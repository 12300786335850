<script setup lang="ts">
import type { PromoData, Promo } from "@/types";

defineProps<{
	locationTid?: string;
}>();

const { t } = useT();
const { isMobile } = useDevice();
const loginGuard = useLoginGuard();
const { open } = useFunrizeModals();
const [isOpenPopper, toggleOpenPopper] = useToggle(false);
const { data: appInitData } = useAppInitData();
const { gameOfWeek } = useHomePage();
const { promotionsData } = usePromotionsPage();
const { dailyWheelData } = useWheels();
const { tournamentData, isSubscribed } = useFunrizeRace();
const { isAdventQuest } = useGameQuest();

const activeOffers = computed(() => {
	const filteredOffers = promotionsData.value.promotions?.reduce<PromoData[]>((acc, { data }) => {
		if (
			(data?.promoOfferPreset?.subType || data?.promoOfferPreset?.mostPopular || data?.promoOfferPreset?.bestDeal) &&
			data
		) {
			acc.push(data);
		}
		return acc;
	}, []);

	return filteredOffers?.sort((paymentA, paymentB) => Number(paymentB?.money) - Number(paymentA?.money));
});

const inviteFriendsData = computed(
	() => promotionsData.value?.promotions?.find((data) => data?.type === "invite") as Promo
);

const showAdventQuest = computed(() => appInitData.value?.gameMode === "SweepStakes" && isAdventQuest.value);

const activePromoNumber = computed(
	() =>
		[
			!!gameOfWeek.value?.id,
			tournamentData.value?.data?.isActive,
			dailyWheelData.value?.dailyWheel?.visible,
			inviteFriendsData.value?.available,
			appInitData.value?.depositStreak?.isActive,
			appInitData.value?.scratchCardLottery?.isActive,
			appInitData.value?.season?.isEnabled,
			appInitData.value?.prizeDrops?.isActive,
			showAdventQuest.value
		].filter(Boolean).length
);

const counterNumber = computed(() => activePromoNumber.value + (activeOffers.value?.length || 0) || 0);

const handleOpenPopper = () => {
	loginGuard({
		success: () => {
			toggleOpenPopper();
		},
		fail: () => {
			open("LazyOModalSignup", { location: "button_right" });
		}
	});
};

const handleClosePopper = () => {
	toggleOpenPopper();
};

watch(
	() => isOpenPopper.value,
	() => {
		if (isOpenPopper.value) {
			dispatchGAEvent({
				event: "open_popup",
				form_name: "reward_cabinet",
				location: "header"
			});
			return;
		}
		dispatchGAEvent({
			event: "close_popup",
			form_name: "reward_cabinet",
			location: "header"
		});
	}
);
</script>

<template>
	<div :class="['rewards-wrapper', { 'popper-open': isOpenPopper, mobile: isMobile }]">
		<LazyAPopper placement="bottom-start" @open:popper="handleOpenPopper" @close:popper="handleClosePopper">
			<div v-if="isMobile" class="widget">
				<NuxtImg
					src="nuxt-img/reward-header.png"
					format="avif"
					width="84"
					height="84"
					alt="reward-box"
					loading="lazy"
				/>
				<AText variant="ternopil" class="text-cannes title" :modifiers="['bold', 'uppercase', 'condensed']">
					{{ t("Rewards") }}
				</AText>
				<AText
					class="btn-counter text-cannes"
					variant="tivat"
					:modifiers="['medium', 'condensed']"
					:data-tid="locationTid ? `count-rewards-${locationTid}` : null"
					as="div"
				>
					{{ counterNumber }}
				</AText>
			</div>
			<div v-else class="btn-rewards" data-tid="header-rewards-btn">
				<div class="container">
					<NuxtImg
						src="nuxt-img/reward-header.png"
						format="avif"
						width="84"
						height="84"
						alt="reward-box"
						loading="lazy"
					/>
					<AText variant="topeka" class="text-cannes title" :modifiers="['bold', 'uppercase', 'condensed']">
						{{ t("Rewards") }}
					</AText>
					<AText class="btn-counter text-cannes" variant="texas" :modifiers="['medium', 'condensed']" as="div">
						{{ counterNumber }}
					</AText>
				</div>
			</div>

			<template v-if="isOpenPopper" #content="{ close }">
				<LazyAOverlay
					v-if="isOpenPopper"
					:modifiers="['fixed', 'flex-center']"
					bgColor="var(--сhifeng)"
					@click="close"
				/>
				<ClientOnly>
					<div class="rewards">
						<div class="top">
							<div class="top-title">
								<AText class="text-cannes" variant="taylor" :modifiers="['bold']">
									{{ t("Available Rewards") }}
								</AText>
								<AText
									class="counter text-cannes"
									variant="tivat"
									:modifiers="['medium', 'condensed']"
									:data-tid="locationTid ? `count-rewards-${locationTid}` : null"
									as="div"
								>
									{{ counterNumber }}
								</AText>
							</div>
							<NuxtIcon
								class="close-icon"
								name="16/close"
								:data-tid="locationTid ? `close-rewards-${locationTid}` : null"
								filled
								@click="close"
							/>
						</div>
						<div class="items">
							<LazyMRewardsGameOfWeek
								v-if="gameOfWeek?.id"
								:data="gameOfWeek"
								:data-tid="locationTid ? `game-of-week-${locationTid}` : null"
								@close-popper="close"
							/>
							<LazyMRewardsTournament
								v-if="tournamentData?.data?.isActive"
								:tournament="tournamentData?.data"
								:is-subscribed="isSubscribed"
								:data-tid="locationTid ? `tournament-${locationTid}` : null"
								@close-popper="close"
							/>
							<LazyMRewardsDailyWheel
								v-if="dailyWheelData?.dailyWheel?.visible"
								:data-tid="locationTid ? `lucky-wheel-${locationTid}` : null"
							/>
							<template v-for="offer in activeOffers" :key="offer?.preset?.id">
								<LazyMRewardsPromo
									v-if="offer"
									:data="offer"
									:data-tid="locationTid ? `reward-${locationTid}` : null"
								/>
							</template>
							<LazyMRewardsInvite
								v-if="inviteFriendsData?.available"
								:referralInfo="inviteFriendsData"
								:data-tid="locationTid ? `invite-${locationTid}` : null"
							/>
							<LazyMRewardsDepositStreak
								v-if="appInitData?.depositStreak?.isActive"
								:data-tid="locationTid ? `deposit-streak-${locationTid}` : null"
							/>
							<LazyMRewardsPrizeDrops
								v-if="appInitData?.prizeDrops?.isActive"
								:data-tid="locationTid ? `prize-drops-${locationTid}` : null"
								@close-popper="close"
							/>
							<LazyMRewardsSeason
								v-if="appInitData?.season?.isEnabled"
								:data-tid="locationTid ? `seasons-${locationTid}` : null"
							/>
							<LazyMRewardsScratchCards
								v-if="appInitData?.scratchCardLottery?.isActive"
								:data-tid="locationTid ? `scratch-${locationTid}` : null"
								@close-popper="close"
							/>
							<LazyMRewardsAdventCalendar
								v-if="showAdventQuest"
								:data-tid="locationTid ? `advent-${locationTid}` : null"
							/>
						</div>
					</div>
				</ClientOnly>
			</template>
		</LazyAPopper>
	</div>
</template>

<style scoped lang="scss">
.rewards-wrapper {
	position: relative;

	&.mobile {
		display: flex;
		width: 74px;
		height: 74px;
		position: fixed;
		right: 0;
		bottom: 130px;
		z-index: 10;
		cursor: pointer;
		overflow: visible;
		z-index: 1999;

		&.popper-open {
			z-index: 2000;
		}

		@media (max-width: 1023px) and (orientation: landscape) {
			bottom: 50px;
		}
	}
}

.widget {
	width: 74px;
	height: 74px;
	background: var(--gyor);
	border-radius: 12px 0px 0px 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 8px;

	img {
		width: 42px;
		height: 42px;
		animation: tilt-shaking 0.5s infinite linear;
	}

	.btn-counter {
		border-radius: 50%;
		background: var(--chimoio);
		width: 16px;
		height: 16px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 6px;
		left: 48px;
	}
}

.btn-rewards {
	position: relative;
	width: max-content;
	background: linear-gradient(90deg, var(--chimoio), var(--cucuta)) border-box;
	-webkit-mask:
		linear-gradient(#fff 0 0) padding-box,
		linear-gradient(#fff 0 0);
	padding: 1px;
	border-radius: 50px;

	@include media-breakpoint-up(lg) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;
		cursor: pointer;
		transition: all 200ms ease-in-out;
	}

	.container {
		width: 100%;
		padding: 8px 16px;
		border-radius: 50px;
		background: var(--changsha);
		display: flex;
		align-items: center;
		gap: 8px;
		position: relative;

		.title {
			transform: translateY(1px);
		}
	}

	img {
		width: 20px;
		height: 20px;
		animation: tilt-shaking 0.5s infinite linear;
	}

	.btn-counter {
		border-radius: 50%;
		background: var(--chimoio);
		width: 14px;
		height: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 3px;
		left: 28px;
	}
}

:deep(.popper) {
	--popper-theme-background-color: transparent;
	--popper-theme-background-color-hover: transparent;
	--popper-theme-box-shadow: 0 7px 10px 0 var(--coronel);

	padding: 0;
	position: fixed !important;
	top: 0 !important;
	left: 0 !important;
	right: 0 !important;
	transform: none !important;
	width: 100vw;
	max-width: 100vw;
	height: auto;
	max-height: 100dvh;
	overflow-y: auto;
	transition: all 400ms ease-in-out !important;

	.popper-open & {
		bottom: 0 !important;
	}

	@include media-breakpoint-up(lg) {
		padding: 0;
		position: fixed !important;
		top: 0 !important;
		transform: none !important;
		width: 100%;
		max-width: 100vw;
		height: 100dvh;
		transition: all 400ms ease-in-out !important;
	}

	.overlay {
		z-index: -1;
		width: 100%;
		height: 100dvh;
	}
}

.rewards {
	width: 100%;
	height: auto;
	max-height: 650px;
	background: var(--cacem);
	position: absolute;
	bottom: 0;
	padding-bottom: 12px;

	@media (max-width: 1023px) and (orientation: landscape) {
		top: 0;
		bottom: unset;
	}

	@include media-breakpoint-up(lg) {
		top: 0;
		right: 0;
		width: 376px;
		height: 100%;
		max-height: 100%;
	}

	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;
		padding: 16px;

		@include media-breakpoint-up(lg) {
			padding-right: 32px;
		}

		&-title {
			display: flex;
			align-items: center;
		}

		.counter {
			border-radius: 50%;
			background: var(--chimoio);
			width: 16px;
			height: 16px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			margin-left: 8px;

			@include media-breakpoint-up(lg) {
				font-size: 16px;
				width: 24px;
				height: 24px;
			}
		}

		.close-icon {
			font-size: 16px;
			transition: all 200ms ease-in-out;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.box {
		@include media-breakpoint-up(lg) {
			padding-right: 32px;
		}
	}
}

@keyframes tilt-shaking {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(5deg);
	}
	50% {
		transform: rotate(0eg);
	}
	75% {
		transform: rotate(-5deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
</style>
